
// MAINVAV

$(".item").each(function () 
{
    
    $(this).first().on("click", function (event) 
    {
        $(".sub-item").each(function()
        {
            if($(this).is(':visible'))
            {
                $(this).slideUp();
            }
        });

        if( $(this).children(".sub-item").is(':visible') )
         {
            $(this).children(".sub-item").stop();
            $(this).children(".sub-item").slideUp();
         }
         else
         {
            $(this).children(".sub-item").stop();
            $(this).children(".sub-item").slideDown();
         }
       
    })
});

$("#burger").on("click", function (){

    if( $(".item").is(':visible') )
    {
        $(".sub-item").each(function()
        {
            if($(this).is(':visible'))
            {
                $(this).slideUp();
            }
        });

        $(".item").slideUp();
    }
    else
    {
        $(".item").slideDown();
        console.log('test 2')
    }
});