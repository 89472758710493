// Utilisation de owl carousel
// https://owlcarousel2.github.io/OwlCarousel2/

$(document).ready(function(){
    $('.owl-carousel').owlCarousel({
        loop:true,
        margin:10,
        padding: 10,
        autoplay: 5000,
        responsiveClass:true,
        responsive:{
            0:{
                items:1,
                nav:false,
                dots: false
            },
            600:{
                items:3,
                nav:false
            },
        }
    })
    });