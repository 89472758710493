// Menu des raccourcis

var shorts = $('.short').children('img')

shorts.each(function () 
{
    $(this).on("mouseenter", function () 
    {
        $('.short').children('img').next().each(function()
        {
            if($(this).is(':visible'))
            {
                $(this).fadeOut()
            }
        });

        $(this).next().css('display', 'flex')
    })

    $(this).next().on("mouseleave", function () 
    {
        $(this).css('display', 'none')
    })
   
})